import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"
import { KEYMAN_PER_PAGE } from "../constants/constants"
import ListLayout from "../components/common/list-layout"
import KeymanItem from "../components/common/keyman-item"
import { Location } from "@reach/router"
import { getQueryVariable } from "../utils/str"
import * as dayjs from "dayjs"

const mapStateToProps = (state, ownProps) => {
  return {
    keymans: state.update.keymans.toIndexedSeq().toArray(),
    pageInfo: state.update.pageInfo_keyman,
    isLoading: state.update.isLoadingKeymans,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    keymanCountYstd: state.update.keymanCountYstd,
    isInitialized: state.update.isInitialized,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymans: (data, overwrite) =>
      dispatch(actions.getKeymans(data, overwrite)),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
  }
}

const styles = theme => ({})

class Update extends React.Component {
  componentDidMount() {
    this.props.getKeymansTotal()
  }

  getQuery = (props, query = {}) => {
    return {
      paging: 1,
      per_page: KEYMAN_PER_PAGE,
      page: 1,
      created_at: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
      ...getQueryVariable(props.location.search, ["page"]),
      ...query,
    }
  }

  render() {
    const {
      keymans,
      isLoading,
      pageInfo,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
    } = this.props

    return (
      <ListLayout
        name="昨日更新された決裁者"
        title="昨日更新された決裁者一覧"
        ItemComponent={KeymanItem}
        items={keymans}
        isLoading={isLoading}
        perPage={KEYMAN_PER_PAGE}
        pageInfo={pageInfo}
        getItems={(data, overwrite) =>
          this.props.getKeymans(this.getQuery(this.props, data), overwrite)
        }
        keymanCountYstd={keymanCountYstd}
        keymansTotal={keymansTotal}
        corporationsTotal={corporationsTotal}
        isShowUpdateInfo={false}
        path="update"
        isInitialized={this.props.isInitialized}
      />
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Update {...locationProps} {...props} />}
    </Location>
  ))
)
